<template>
  <div
    style="display: flex"
    @click="
      showSelect = false;
      showSaleSelect = false;
    "
  >
    <div class="left">
      <div class="idCard">
        <div class="headImg">
          <img :src="userInfo.avatar" />
        </div>
        <div class="text">
          <div class="name">{{ userInfo.realname }}</div>
          <div class="about">{{ userInfo.hierarchy_name }}</div>
        </div>
        <div class="systemAbout">{{ userInfo.role }}</div>
      </div>
      <div style="padding-left: 20px">
        <div class="title">智能助手</div>
        <!-- <div class="tips">
          <div class="circle"></div>
          <div style="width: 78%; display: flex">
            您有 <span style="color: #fd563a">6条</span> 客户提醒未处理
            <div class="check">查看</div>
          </div>
          <div class="time">1分钟前</div>
        </div>
        <div class="tips">
          <div class="circle"></div>
          <div style="width: 78%; display: flex">
            您有 <span style="color: #fd563a">6条</span> 客户提醒未处理
            <div class="check">查看</div>
          </div>
          <div class="time">15分钟前</div>
        </div> -->
        <div class="intelligent" v-if="store_notice_list.length !== 0">
          <div
            class="tips"
            v-for="(item, index) in store_notice_list"
            :key="index"
          >
            <div
              class="check"
              v-if="item.notice_type === 10"
              style="background: #e7541e"
            >
              办公
            </div>
            <div
              class="check"
              v-if="item.notice_type === 20"
              style="background: #1678ff"
            >
              报告
            </div>
            <div
              class="check"
              v-if="item.notice_type === 30"
              style="background: #ffae00"
            >
              任务
            </div>
            <div style="width: 58%">
              {{ item.content }}
            </div>
            <div class="time">{{ item.create_time }}</div>
          </div>
        </div>
        <div class="intelligent" v-else>
          <img
            src="../../../src/assets/images/home/noAd.png"
            style="width: 230px; height: auto; margin-left: 25%"
          />
          <div
            style="
              text-align: center;
              margin-left: -20px;
              margin-top: -10px;
              font-size: 14px;
            "
          >
            暂无数据
          </div>
        </div>
        <div class="title" style="margin-top: 30px">我的常用</div>
        <div class="application">
          <div class="box" @click="toApplication('createOrder')">
            <img src="../../../src/assets/images/home/icon_establish.png" />
            <div>创建订单</div>
          </div>
          <div class="box" @click="toApplication('godownEntryList')">
            <img src="../../../src/assets/images/home/icon_ruku.png" />
            <div>新品入库</div>
          </div>
          <div class="box" @click="toApplication('goodsDetail')">
            <img src="../../../src/assets/images/home/icon_qingdang.png" />
            <div>货品清单</div>
          </div>
          <div class="box" @click="toApplication('rfid')">
            <img src="../../../src/assets/images/home/icon_rfid.png" />
            <div>RFID管理</div>
          </div>
          <div class="box" @click="toApplication('inventory')">
            <img src="../../../src/assets/images/home/icon_huopinpandian.png" />
            <div>货品盘点</div>
          </div>
          <div class="box" @click="toApplication('transfer')">
            <img src="../../../src/assets/images/home/icon_diaobo.png" />
            <div>货品调拨</div>
          </div>
          <div class="box" @click="toApplication('order')">
            <img src="../../../src/assets/images/home/icon_xianxia.png" />
            <div>销售订单</div>
          </div>
          <div class="box" @click="toApplication('returnOrder')">
            <img src="../../../src/assets/images/home/icon_tuihuo.png" />
            <div>退货订单</div>
          </div>
          <div
            class="box"
            @click="toApplication('member')"
            style="margin-bottom: 10px"
          >
            <img src="../../../src/assets/images/home/icon_huiyuan.png" />
            <div>会员列表</div>
          </div>
          <div
            class="box"
            @click="toApplication('activity')"
            style="margin-bottom: 10px !important"
          >
            <img src="../../../src/assets/images/home/icon_huodonliebiao.png" />
            <div>活动列表</div>
          </div>
          <div
            class="box"
            @click="toApplication('authority')"
            style="margin-bottom: 10px !important"
          >
            <img src="../../../src/assets/images/home/icon_yuangon.png" />
            <div>员工列表</div>
          </div>
          <div
            class="box"
            @click="toApplication('clockIn')"
            style="margin-bottom: 10px !important"
          >
            <img src="../../../src/assets/images/home/icon_dakatonji.png" />
            <div>打卡统计</div>
          </div>
          <!-- <div
            class="box"
            @click="toApplication('order')"
            style="margin-bottom: 10px"
          >
            <img src="../../../src/assets/images/home/icon_order.png" />
            <div>订单管理</div>
          </div>
          <div
            class="box"
            @click="toApplication('authority')"
            style="margin-bottom: 10px"
          >
            <img src="../../../src/assets/images/home/icon_staff.png" />
            <div>员工管理</div>
          </div> -->
        </div>
        <!-- <div class="title" style="margin-top: 30px">天比高推荐</div>
        <div class="recommend">
          <div class="recommend-box">
            <img src="../../../src/assets/images/home/icon_one.png" />
            <div style="font-size: 14px; color: #909399; text-align: center">
              团队协作的重要性
            </div>
          </div>
          <div class="recommend-box">
            <img src="../../../src/assets/images/home/icon_two.png" />
            <div style="font-size: 14px; color: #909399">
              顾问式销售之SPIN模式
            </div>
          </div>
          <div class="recommend-box">
            <img src="../../../src/assets/images/home/icon_three.png" />
            <div style="font-size: 14px; color: #909399; text-align: center">
              塑造灵魂人物[店长]
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="right">
      <div class="top">
        <el-form style="display: flex">
          <el-form-item>
            <el-select
              v-model="form.hierarchy_id"
              placeholder="请选择门店"
              style="width: 200px; margin-right: 10px"
              @change="handleHierarchy"
            >
              <template v-for="(item, index) in store_list">
                <el-option
                  :label="item.hierarchy_name"
                  :value="item.hierarchy_id"
                  :key="index"
                ></el-option>
              </template>
            </el-select>
          </el-form-item>
          <div class="select" @click.stop="handleChange">
            <span v-if="currTime.field === 'times'">
              {{ currTime.title[0] }}
              <span style="margin: 0 10px">至</span>
              {{ currTime.title[1] }}
            </span>
            <span v-else>{{ currTime.title }}</span>
            <i
              class="el-select__caret el-input__icon el-icon-arrow-down"
              :class="showSelect ? 'up' : ''"
            ></i>
            <div class="triangle" v-show="showTriangle"></div>
            <div
              class="timeBox"
              @click.stop=""
              :style="
                showSelect
                  ? { height: '365px', boxShadow: '2px 5px 5px #ccc' }
                  : {}
              "
            >
              <div class="list">
                <div class="item" v-for="item in timeList" :key="item.id">
                  <span>{{ item.title }}</span>
                  <template v-for="_ in item.arr">
                    <div
                      :key="_.field"
                      :style="
                        _.field === 'last_quarter' ? { marginLeft: '17px' } : {}
                      "
                      @click="chooseTime(_)"
                      :class="dropdownCurr.field === _.field ? 'active' : ''"
                    >
                      {{ _.title }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="selectTime">
                <div style="text-indet: 20px">自定义</div>
                <el-date-picker
                  @change="handlePicker"
                  v-model="times"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  value-format="yyyy-MM-dd"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
              <div class="selectBottom">
                <el-checkbox v-model="isDefault">保存为默认值</el-checkbox>
                <el-button
                  type="primary"
                  plain
                  size="mini"
                  style="width: 70px"
                  @click="handleTrue"
                  >确定</el-button
                >
              </div>
            </div>
          </div>
        </el-form>
        <!-- <div class="mainBox">
          <div
            class="box"
            style="background: linear-gradient(#e570ee 0%, #9264f6 100%)"
          >
            <div class="title">门店毛利</div>
            <div class="num"><span style="font-size: 20px">￥</span>270000</div>
          </div>
          <div
            class="box"
            style="background: linear-gradient(#5aabfe 0%, #597bf7 100%)"
          >
            <div class="title">订单销售额</div>
            <div class="num"><span style="font-size: 20px">￥</span>270000</div>
          </div>
          <div
            class="box"
            style="background: linear-gradient(#fd7e5d 0%, #ff628a 100%)"
          >
            <div class="title">货品库存</div>
            <div class="num">270000</div>
          </div>
          <div
            class="box"
            style="background: linear-gradient(#0ecdba 0%, #00bef4 100%)"
          >
            <div class="title">会员总数</div>
            <div class="num">270</div>
          </div>
        </div> -->
        <div class="mainData" style="margin-top: 28px">
          <el-tooltip
            class="item"
            effect="dark"
            content="业绩金额=销售金额-退货金额-回收金额（扣除赎回）"
            placement="top"
          >
            <div
              class="mainBox"
              style="background: linear-gradient(to right, #e570ee, #9264f6)"
            >
              <div class="mainBox_title">业绩金额</div>
              <div class="mainBox_num">
                <span style="font-size: 14px !important">￥</span
                >{{ briefInfo.profit_amount || 0 }}
              </div>
            </div>
          </el-tooltip>

          <div class="dataAbout">
            <div class="box">
              <div class="number">{{ briefInfo.sale_num }}</div>
              <div class="text">销售数量</div>
            </div>
            <el-tooltip
              class="item"
              effect="dark"
              content="销售金额=订单总额 - 优惠总额"
              placement="top"
            >
              <div class="box">
                <div class="number">{{ briefInfo.sale_amount }}</div>
                <div class="text">销售金额</div>
              </div>
            </el-tooltip>
            <div class="box">
              <div class="number">{{ briefInfo.refund_amount }}</div>
              <div class="text">退货金额</div>
            </div>
            <div class="box">
              <div class="number">{{ briefInfo.refund_num }}</div>
              <div class="text">退货数量</div>
            </div>
            <el-tooltip
              class="item"
              effect="dark"
              content="回收重量=回收重量-赎回重量"
              placement="top"
            >
              <div class="box">
                <div class="number">
                  {{ briefInfo.recycle_amount }}
                </div>
                <div class="text">回收金额</div>
              </div>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="回收重量=回收重量-赎回重量"
              placement="top"
            >
              <div class="box">
                <div class="number">{{ briefInfo.recycle_weight || 0 }}</div>
                <div class="text">回收重量</div>
              </div>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="回收重量=回收重量-赎回重量"
              placement="top"
            >
              <div class="box">
                <div class="number">{{ briefInfo.recycle_num }}</div>
                <div class="text">回收数量</div>
              </div>
            </el-tooltip>
            <!-- <div class="box">
              <div class="number">{{ briefInfo.prestore_amount }}</div>
              <div class="text">预存金额</div>
            </div>
            <div class="box">
              <div class="number">{{ briefInfo.prestore_verification }}</div>
              <div class="text">预存核销</div>
            </div> -->
          </div>
        </div>
        <div
          class="mainData"
          style="background: #f5f6fa; border-radius: 6px; margin-top: 25px"
        >
          <div
            class="mainBox"
            style="background: linear-gradient(to right, #5aabfe, #597bf7)"
          >
            <div class="mainBox_title">货品库存</div>
            <div class="mainBox_num">{{ briefInfo.stock_num }}</div>
          </div>
          <div class="dataAbout">
            <div class="box">
              <div class="number">{{ briefInfo.new_goods_num }}</div>
              <div class="text">新品入库</div>
            </div>
            <div class="box">
              <div class="number">{{ briefInfo.stock_out_num }}</div>
              <div class="text">货品出库</div>
            </div>
            <div class="box">
              <div class="number">{{ briefInfo.sale_unit_num }}</div>
              <div class="text">非素数量</div>
            </div>
            <div class="box">
              <div class="number">{{ briefInfo.sale_unit_weight }}</div>
              <div class="text">非素重量(g)</div>
            </div>
            <div class="box">
              <div class="number">{{ briefInfo.sale_weight_num }}</div>
              <div class="text">素金数量</div>
            </div>
            <div class="box">
              <div class="number">{{ briefInfo.sale_weight_weight }}</div>
              <div class="text">素金重量(g)</div>
            </div>
          </div>
        </div>
        <div class="mainData" style="margin-top: 25px">
          <div
            class="mainBox"
            style="background: linear-gradient(to right, #fd7e5d, #ff628a)"
          >
            <div class="mainBox_title">会员数</div>
            <div class="mainBox_num">{{ briefInfo.client_user }}</div>
          </div>
          <div class="dataAbout">
            <div class="box">
              <div class="number">{{ briefInfo.new_user }}</div>
              <div class="text">新增会员</div>
            </div>
            <div class="box">
              <div class="number">{{ briefInfo.new_vip_num }}</div>
              <div class="text">新客消费数</div>
            </div>
            <div class="box">
              <div class="number">{{ briefInfo.old_vip_num }}</div>
              <div class="text">老客消费数</div>
            </div>
            <div class="box">
              <div class="number">{{ briefInfo.tract_num }}</div>
              <div class="text">跟进客户数</div>
            </div>
            <div class="box">
              <div class="number">{{ briefInfo.employee_num }}</div>
              <div class="text">员工数</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom" style="margin-top: 10px">
        <el-tabs v-model="activeName">
          <el-tab-pane label="销售统计" name="one"> </el-tab-pane>
          <!-- <el-tab-pane label="货品统计" name="two"> </el-tab-pane>
          <el-tab-pane label="会员统计" name="three"> </el-tab-pane>
          <el-tab-pane label="门店经营" name="four"> </el-tab-pane> -->
        </el-tabs>
        <div>
          <el-form style="display: flex">
            <div
              class="select"
              @click.stop="handleSaleChange"
              style="margin: 0"
            >
              <span v-if="currSaleTime.field === 'times'">
                {{ currSaleTime.title[0] }}
                <span style="margin: 0 10px">至</span>
                {{ currSaleTime.title[1] }}
              </span>
              <span v-else>{{ currSaleTime.title }}</span>
              <i
                class="el-select__caret el-input__icon el-icon-arrow-down"
                :class="showSaleSelect ? 'up' : ''"
              ></i>
              <div class="triangle" v-show="showSaleTriangle"></div>
              <div
                class="timeBox"
                @click.stop=""
                :style="
                  showSaleSelect
                    ? { height: '365px', boxShadow: '2px 5px 5px #ccc' }
                    : {}
                "
              >
                <div class="list">
                  <div class="item" v-for="item in timeList" :key="item.id">
                    <span>{{ item.title }}</span>
                    <template v-for="_ in item.arr">
                      <div
                        :key="_.field"
                        :style="
                          _.field === 'last_quarter'
                            ? { marginLeft: '17px' }
                            : {}
                        "
                        @click="chooseSaleTime(_)"
                        :class="
                          dropdownCurrSale.field === _.field ? 'active' : ''
                        "
                      >
                        {{ _.title }}
                      </div>
                    </template>
                  </div>
                </div>
                <div class="selectTime">
                  <div style="text-indet: 20px">自定义</div>
                  <el-date-picker
                    @change="handleSalePicker"
                    v-model="saleTimes"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    value-format="yyyy-MM-dd"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
                <div class="selectBottom">
                  <el-checkbox v-model="isDefault">保存为默认值</el-checkbox>
                  <el-button
                    type="primary"
                    plain
                    size="mini"
                    style="width: 70px"
                    @click="handleSaleTrue"
                    >确定</el-button
                  >
                </div>
              </div>
            </div>
          </el-form>
          <div style="display: flex; width: 100%" class="dataMap">
            <div style="width: 55%; margin-right: 60px">
              <div style="margin-top: 16px">
                <div class="title" style="font-size: 16px; margin-bottom: 24px">
                  销售业绩趋势
                </div>
              </div>
              <div id="container" style="height: 225px"></div>
            </div>
            <div style="width: 39%; margin-top: 16px">
              <div class="title" style="font-size: 16px; line-height: 20px">
                销售品类占比
              </div>
              <div id="canvas" style="height: 225px"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  noticeList,
  getMerchantList,
  brief,
  hotType,
  sale_trend,
} from "@/api/index.js";
import { Pie, DualAxes, Line } from "@antv/g2plot";
import storage from "good-storage";

export default {
  name: "Index",
  data() {
    const userInfo = storage.get("userInfo");
    // const hierarchy_id = storage.get("userInfo").hierarchy_id;
    return {
      hierarchy_id: null,
      userInfo,
      form: {},
      times: [],
      dualAxes: null,
      piePlot: null,
      activeName: "one",
      store_notice_list: [], // 智能助手
      store_list: [],
      briefInfo: {},
      showTriangle: false, // 展示三角形
      isDefault: false,
      timeList: [
        {
          id: 0,
          title: "天",
          arr: [
            { title: "今天", field: "today" },
            { title: "昨天", field: "yesterday" },
          ],
        },
        {
          id: 1,
          title: "周",
          arr: [
            { title: "本周", field: "week" },
            { title: "上周", field: "last_week" },
          ],
        },
        {
          id: 2,
          title: "月",
          arr: [
            { title: "本月", field: "month" },
            { title: "上月", field: "last_month" },
          ],
        },
        {
          id: 3,
          title: "季",
          arr: [
            { title: "本季度", field: "this_quarter" },
            { title: "上一季度", field: "last_quarter" },
          ],
        },
        {
          id: 4,
          title: "年",
          arr: [
            { title: "今年", field: "year" },
            { title: "去年", field: "last_year" },
          ],
        },
      ],
      showSelect: false,
      currTime: { title: "本月", field: "month" },
      dropdownCurr: { title: "本月", field: "month" }, // 下拉框当前点击，当点击确定时赋值给currTime
      showSaleTriangle: false,
      showSaleSelect: false,
      currSaleTime: { title: "本月", field: "month" },
      dropdownCurrSale: { title: "本月", field: "month" }, // 下拉框当前点击，当点击确定时赋值给currTime
      saleTimes: [],
      mockData: [],
      column: [],
      transformData: [],
    };
  },
  computed: {
    time() {
      if (this.currTime.field !== "times") {
        return this.currTime.field;
      }
      return this.currTime.title;
    },
    saleTime() {
      if (this.currSaleTime.field !== "times") {
        return this.currSaleTime.field;
      }
      return this.currSaleTime.title;
    },
  },
  mounted() {
    this.hotType();
    this.sale_trend();
  },
  created() {
    this.noticeList();
    this.getMerchantList();
    this.getBrief();
  },
  methods: {
    /*
     *  智能助手
     */
    noticeList() {
      noticeList({ is_finish: 10, page_num: 5 }).then((res) => {
        if (res.code === 1) {
          this.store_notice_list = res.data.list_data.list;
        }
      });
    },
    /*
     *   门店下拉
     */
    getMerchantList() {
      getMerchantList().then((res) => {
        if (res.code === 1) {
          this.store_list = res.data;
          this.form.hierarchy_id = res.data[0].hierarchy_id;
        }
      });
    },
    /*
     *  数据简报
     */
    getBrief() {
      const data = {
        hierarchy_id: this.form.hierarchy_id,
        time: this.time,
      };
      brief(data).then((res) => {
        if (res.code === 1) {
          this.briefInfo = res.data;
        }
      });
    },
    /*
     *  常用应用跳转
     */
    toApplication(item) {
      switch (item) {
        case "createOrder":
          this.$router.push({
            name: "offlineOrderSalesOrder",
            query: {
              t: Date.now(),
            },
            params: {
              type: "add",
            },
          });
          break;
        case "godownEntryList":
          this.$router.push({
            path: "/goods/godownEntry/godownEntryList",
          });
          break;
        case "inventory":
          this.$router.push({
            path: "/goods/goodsInventory/list",
          });
          break;
        case "transfer":
          this.$router.push({
            path: "/goods/goodsTransfer/transfer",
          });
          break;
        case "store":
          this.$router.push({
            path: "/goods/goodsStore/list",
          });
          break;
        case "goodsDetail":
          this.$router.push({
            path: "/goods/goodsDetailed/list",
          });
          break;
        case "order":
          this.$router.push({
            path: "/order/offlineOrder/salesOrder",
            query: {
              t: Date.now(),
            },
          });
          break;
        case "member":
          this.$router.push({
            path: "/member/memberList/list",
          });
          break;
        case "authority":
          this.$router.push({
            path: "/authority/menage",
          });
          break;
        case "rfid":
          this.$router.push({
            path: "/goods/RFID/list",
          });
          break;
        case "returnOrder":
          this.$router.push({
            path: "/order/offlineOrder/returnOrder",
          });
          break;
        case "activity":
          this.$router.push({
            path: "/marketing/activity/activityList/list",
          });
          break;
        case "clockIn":
          this.$router.push({
            path: "/work/handleWork/clockIn",
          });
          break;
        default:
          break;
      }
    },
    handleHierarchy(event) {
      this.hierarchy_id = event;
      this.getBrief();
    },
    handleChange() {
      this.showSelect = !this.showSelect;
    },
    handleSaleChange() {
      this.showSaleSelect = !this.showSaleSelect;
    },
    handleTrue() {
      this.currTime = this.dropdownCurr;
      this.showSelect = false;
      if (this.isDefault) {
        this.setDefult();
      }
      this.getBrief();
    },
    handleSaleTrue() {
      this.currSaleTime = this.dropdownCurrSale;
      this.showSaleSelect = false;
      this.sale_trend();
      this.hotType();
    },
    handlePicker() {
      this.dropdownCurr = {
        field: "times",
        title: this.times,
      };
    },
    handleSalePicker() {
      this.dropdownCurrSale = {
        field: "times",
        title: this.saleTimes,
      };
    },
    // 下拉框选择时间
    chooseSaleTime(curr) {
      this.dropdownCurrSale = curr;
      this.SaleTimes = [];
    },
    handleClick() {
      this.$router.push({
        name: "Menage",
      });
    },
    // 下拉框选择时间
    chooseTime(curr) {
      this.dropdownCurr = curr;
      this.times = [];
    },
    /*
     *  销售品类占比
     */
    hotType() {
      const data = {
        hierarchy_id: this.form.hierarchy_id,
        time: this.saleTime,
      };
      hotType(data).then((res) => {
        if (res.code === 1) {
          if (res.data.length !== 0) {
            this.mockData = res.data.map((item) => {
              item.type = item.type_name;
              item.value = parseFloat(
                parseFloat(item.actual_sale_price).toFixed(2)
              );
              return item;
            });
            if (this.piePlot) {
              this.piePlot.destroy();
            }
            this.piePlot = new Pie(document.getElementById("canvas"), {
              appendPadding: 10,
              radius: 0.7,
              data: this.mockData,
              angleField: "value",
              colorField: "type",
              label: {
                type: "outer",
                content: "{name}：{value}元",
              },
              interactions: [
                { type: "pie-legend-active" },
                { type: "element-active" },
              ],
            });
            setInterval(() => {
              this.userInfo = storage.get("userInfo");
            }, 1000);

            this.piePlot.render();
          }
        }
      });
    },
    /*
     *  销售业绩趋势
     */
    sale_trend() {
      const data = {
        hierarchy_id: this.form.hierarchy_id,
        time: this.saleTime,
      };
      sale_trend(data).then((res) => {
        if (res.code === 1) {
          if (res.data.length !== 0) {
            this.transformData = res.data.map((item) => {
              const info = {};
              info.time = item.day;
              info.count = item.QoQ_rate;
              info.name = "月度环比（%）";
              return info;
            });
            const orderInfo = res.data.map((item) => {
              const info = {};
              info.time = item.day;
              info.value = item.order_amount;
              info.type = "订单销售额（元）";
              return info;
            });

            const paidInfo = res.data.map((item) => {
              const info = {};
              info.time = item.day;
              info.value = item.paid_in_amount;
              info.type = "订单实收额（元）";
              return info;
            });

            this.column = [...orderInfo, ...paidInfo];
            if (this.dualAxes) {
              this.dualAxes.destroy();
            }
            // 折线图
            this.dualAxes = new Line("container", {
              data: this.column,
              xField: "time",
              yField: "value",
              seriesField: "type",
              xAxis: {
                label: {
                  type: "time",
                },
              },
              smooth: true,
              animation: {
                appear: {
                  animation: "path-in",
                  duration: 5000,
                },
              },
              color: ["#2791ff", "#37C3B7", ""],
            });

            this.dualAxes.render();
          }
        }
      });
    },
  },
};
</script>

<style lang='less' scoped>
/deep/ .el-tabs__item {
  font-size: 16px !important;
  line-height: 20px !important;
}

/deep/ .el-form-item {
  margin-bottom: 0px;
}

/deep/ .left {
  width: 27%;
  padding-bottom: 30px;
  background: #ffffff;
  border-radius: 6px;

  .idCard {
    position: relative;
    margin-bottom: 15px;
    width: 100%;
    height: 136px;
    background-image: url("../../../src/assets/images/home/icon_bg.png");
    background-repeat: no-repeat;
    background-size: cover;

    .headImg {
      position: absolute;
      left: 43px;
      top: 26px;
      width: 82px;
      height: 82px;
      border-radius: 50%;
      background-color: white;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .text {
      position: absolute;
      left: 140px;
      top: 36px;
      color: white;

      .name {
        font-size: 26px;
        font-family: PingFang SC Medium, PingFang SC Medium-Medium !important;
      }

      .about {
        margin-top: 12px;
        font-size: 14px;
      }
    }

    .systemAbout {
      position: absolute;
      top: 20px;
      right: 0;
      width: auto;
      padding: 0 15px;
      height: 27x;
      background: #eaf9ff;
      border-radius: 16px 0px 0px 16px;
      line-height: 32px;
      color: #2791ff;
      text-align: center;
      font-size: 14px;
    }
  }
  .title {
    font-size: 16px;
    line-height: 38px;
    font-family: PingFang SC Regular;
    font-weight: 500;
    color: #2b343d;
  }
  .intelligent {
    height: 200px;
  }
  .tips {
    display: flex;
    font-size: 14px;
    line-height: 38px;

    .time {
      width: 28%;
      text-align: right;
      font-size: 14px;
      color: #c0c4cc;
    }

    .check {
      margin-top: 10px;
      margin-right: 10px;
      width: 36px;
      height: 18px;
      font-size: 8px;
      background: #fd563a;
      color: white;
      line-height: 18px;
      border-radius: 5px;
      text-align: center;
    }
  }
  .tips .circle {
    width: 7px;
    height: 7px;
    background: #fd563a;
    border-radius: 50%;
    margin: auto 9px auto 5px;
  }

  .application {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-left: -10px;
    width: 100%;

    .box {
      margin-bottom: 35px;
      width: 25%;
      text-align: center;
      font-size: 14px;
      img {
        margin-bottom: 10px;
        width: 50px;
        height: 50px;
      }
    }

    .box:hover {
      cursor: pointer;
    }
  }

  .recommend {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .recommend-box {
      width: 33%;
      img {
        display: inline;
        margin-top: 10px;
        width: 90%;
        height: 76px;
        border-radius: 6px;
      }
    }
  }
}

.right {
  margin-left: 10px;
  width: 70%;

  .top {
    padding: 17px 20px 29px 18px;
    width: 100%;
    border-radius: 6px;
    background: #ffffff;
  }

  .bottom {
    padding: 20px;
    width: 100%;
    border-radius: 6px;
    background: #ffffff;

    .dataMap {
      .title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 30px;
      }
    }
  }

  .mainData {
    display: flex;
    width: 100%;
    height: 84px;
    .mainBox {
      margin-right: 15px;
      width: 200px;
      height: 84px;
      border-radius: 6px;
      color: white;

      .mainBox_title {
        margin-top: 13px;
        margin-left: 20px;
        font-size: 16px;
      }

      .mainBox_num {
        margin-top: 5px;
        margin-left: 20px;
        font-size: 22px;
        font-family: Adobe Heiti Std R, Adobe Heiti Std R-R;
        font-weight: R;
      }
    }
    .dataAbout {
      display: flex;
      width: 80%;
      height: 84px;
      .box {
        padding-top: 20px;
        width: 145px;
        text-align: center;

        .number {
          font-size: 20px;
          font-family: D-DIN DIN-Bold, D-DIN DIN-Bold-DIN-Bold;
          font-weight: DIN-Bold;
        }

        .text {
          margin-top: 6px;
          font-size: 12px;
          color: #909399;
        }
      }
    }
    img {
      margin: auto 10px auto 0;
      width: 20px;
      height: 22px;
    }
    .tittle {
      line-height: 73px;
      font-size: 14px;
    }
    .num {
      margin-left: 14px;
      font-size: 24px;
      font-weight: 500;
      line-height: 73px;
    }
  }
}
.select {
  color: #606266;
  cursor: pointer;
  background: white;
  padding: 0 10px 0 15px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  margin-left: 10px;
  position: relative;
  .el-icon-arrow-down {
    color: #c0c4cc;
    cursor: pointer;
    margin-left: 10px;
    transform: rotateZ(0deg);
  }
  .up {
    transform: rotateZ(180deg);
  }
}
.triangle {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid white;
  position: absolute;
  top: 42px;
  left: 27px;
  z-index: 9;
}
.timeBox {
  background: white;
  position: absolute;
  top: 48px;
  left: -3px;
  height: 0px;
  overflow: hidden;
  transition: all 0.2s;
  z-index: 999;
}
.selectBottom {
  display: flex;
  align-items: center;
  background: white;
  padding: 10px 20px;
  justify-content: space-between;
}
.selectTime {
  padding: 10px 10px;
  background: rgb(240, 240, 240);
  div {
    font-size: 12px;
    margin-bottom: 10px;
  }
}
.list {
  width: 260px;
  padding: 10px 15px 10px 15px;
  box-sizing: border-box;
  font-size: 12px;
  .item {
    display: flex;
    line-height: 23px;
    .active {
      background: #409eff;
      color: white !important;
    }
    span {
      padding: 5px 10px;
      color: #909399;
      cursor: pointer;
    }
    div {
      padding: 5px 10px;
      cursor: pointer;
      margin-left: 30px;
    }
    div:hover {
      color: #409eff;
    }
  }
}
</style>